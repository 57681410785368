import React from 'react';
import classNames from "classnames";
import { Link } from 'react-navi';
import { useResource } from 'retil';
import {Entries} from '@2024-sophians-web/components/src/components';
import {Entry} from "@2024-sophians-web/components/src/types";
import { StepBox } from '@2024-sophians-web/components/src/components/StepBox';
import { Hero, Footer } from '../../components';
import {entryList} from "../../resources";
import {EntryCard} from "../../components/card";
import { newsList } from '../../resources/news';

export function TopApp() {
  const [entries] = useResource(entryList);
  const [news] = useResource(newsList);
  return (
      <main className="TopApp">
        {/*style={{paddingBottom: 80}}*/}
        <Hero/>
        {/* FINALIST */}
        <section id="finalist" className="section">
          <div className="container">
            <div className="columns is-multiline is-mobile is-margin-bottom-3">
              <Entries state={entries} noshuffle>
                {(list: Entry[]) =>
                    list.map((item: Entry) => (
                        <div
                            className={classNames('column is-4-tablet is-6-mobile')}
                            key={item.entry_id}
                        >
                          <EntryCard onlive={false} entry={item} href={`/profiles/${item.entry_id}`}/>
                        </div>
                    ))
                }
              </Entries>
            </div>
          </div>
        </section>
        {/* SPONSERS */}
        <section className="section" style={{backgroundColor: "rgb(253, 253, 253)" }}>
          <div className="container" style={{maxWidth: 720}}>
            <div className="is-margin-bottom-3">
              <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Sponsors</h2>
            </div>
            <div className="theme-box has-text-centered is-white is-normal">
              <div>
                <figure className="is-margin-bottom-1-5 is-margin-bottom-0-5-mobile">
                  <img src="/img/sponsors/reabroad.jpg" width="569" height="320"/></figure>
                <h2 className="is-size-7-mobile has-text-centered is-margin-bottom-4 is-margin-bottom-2-mobile">株式会社リアブロード 様</h2>
                <figure className="is-margin-bottom-1-5 is-margin-bottom-0-5-mobile">
                  <img src="/img/sponsors/rulie.jpg" width="569" height="320"/></figure>
                <h2 className="is-size-7-mobile has-text-centered is-margin-bottom-4 is-margin-bottom-2-mobile">株式会社Rulie 様</h2>
                <figure className="is-margin-bottom-1-5 is-margin-bottom-0-5-mobile">
                  <img src="/img/sponsors/kadcul.jpg" width="569" height="320"/></figure>
                <h2 className="is-size-7-mobile has-text-centered is-margin-bottom-4 is-margin-bottom-2-mobile">角川武蔵野ミュージアム 様</h2>
                <figure className="is-margin-bottom-1-5 is-margin-bottom-0-5-mobile">
                  <img src="/img/sponsors/anaguma.jpg" width="320" height="320"/></figure>
                <h2 className="is-size-7-mobile has-text-centered is-margin-bottom-4 is-margin-bottom-2-mobile">株式会社穴熊 様</h2>
                <figure className="is-margin-bottom-1-5 is-margin-bottom-0-5-mobile">
                  <img src="/img/sponsors/drsmile.jpg" width="569" height="320"/></figure>
                <h2 className="is-size-7-mobile has-text-centered is-margin-bottom-4 is-margin-bottom-2-mobile">株式会社スマイルアンドトゥース 様</h2>
                <figure className="is-margin-bottom-1-5 is-margin-bottom-0-5-mobile">
                  <img src="/img/sponsors/salonia.jpg" width="569" height="320"/></figure>
                <h2 className="is-size-7-mobile has-text-centered is-margin-bottom-4 is-margin-bottom-2-mobile">株式会社I-ne 様</h2>
                <figure className="is-margin-bottom-1-5 is-margin-bottom-0-5-mobile">
                  <img src="/img/sponsors/hho.jpg" width="320" height="320"/></figure>
                <h2 className="is-size-7-mobile has-text-centered is-margin-bottom-4 is-margin-bottom-2-mobile">株式会社HHO Limited 様</h2>
                <figure className="is-margin-bottom-1-5 is-margin-bottom-0-5-mobile">
                  <img src="/img/sponsors/eqao.jpg" width="569" height="320"/></figure>
                <h2 className="is-size-7-mobile has-text-centered is-margin-bottom-4 is-margin-bottom-2-mobile">株式会社EQAO 教育グループ 様</h2>
                <figure className="is-margin-bottom-1-5 is-margin-bottom-0-5-mobile">
                  <img src="/img/sponsors/yamazaki.jpg" width="569" height="320"/></figure>
                <h2 className="is-size-7-mobile has-text-centered is-margin-bottom-4 is-margin-bottom-2-mobile">株式会社ヤマザキビスケット 様</h2>
                <figure className="is-margin-bottom-1-5 is-margin-bottom-0-5-mobile">
                  <img src="/img/sponsors/international.jpg" width="569" height="320"/></figure>
                <h2 className="is-size-7-mobile has-text-centered is-margin-bottom-4 is-margin-bottom-2-mobile">株式会社インターナショナルフォワーディング 様</h2>
                <figure className="is-margin-bottom-1-5 is-margin-bottom-0-5-mobile">
                  <img src="/img/sponsors/kadokura.jpg" width="320" height="320"/></figure>
                <h2 className="is-size-7-mobile has-text-centered is-margin-bottom-4 is-margin-bottom-2-mobile">映画コーディネーター 門倉カド 様</h2>
                <figure className="is-margin-bottom-1-5 is-margin-bottom-0-5-mobile">
                  <img src="/img/sponsors/elsa.jpg" width="320" height="320"/></figure>
                <h2 className="is-size-7-mobile has-text-centered is-margin-bottom-4 is-margin-bottom-2-mobile">ELSA Speak 様</h2>
              </div>
            </div>
          </div>
        </section>
        {/* ABOUT */}
        <section id="about" className="section as-about-section">
          <div className="container" style={{maxWidth: 720}}>
            <div className="is-margin-bottom-3">
              <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">About</h2>
            </div>
            <p
                className="notification is-margin-bottom-3 is-size-7-mobile"
                style={{margin: '0 auto'}}>
              Sophian's
              Contest（ソフィアンズコンテスト）は、上智大学で長年続いていた「ミス・ミスターソフィアコンテスト」を廃止し、
              <strong className="has-text-marker">2020年度から新設されました。</strong>
              <br/>
              男性らしさや女性らしさといった性の画一的な価値観をとりはらった「開かれたコンテスト」の開催を目指しています。
              <br/>
              <br/>
              見える上智生として<strong
                className="has-text-marker">上智大学生としての取り組み及び上智大学の魅力を発信、また自身の取り組みや魅力を効果的に伝える「インフルエンサー」としての活動</strong>を競います。
              <br/>
              <br/>
              このコンテストでは、上智大学の建学理念「キリスト教ヒューマニズム」を基盤とする隣人性「他者のために、他者とともに」を体現化し、上智大学生として社会の先導にたち活躍する人材を輩出することを目指しています。
            </p>
            <div className="theme-box has-text-centered">
              <div>
                <figure className="image is-margin-bottom-3">
                  <img src="/img/bg/theme.png"/>
                </figure>
                <div className="is-margin-bottom-1">
                  <p className="has-text-weight-bold is-size-7 is-margin-bottom-0-5">コンテストテーマ</p>
                  <h3 className="is-size-3 is-size-4-mobile">
                    <figure className="image as-theme-logo" style={{maxWidth: 240, margin: '0 auto'}}>
                      <img src="/img/theme.png"/>
                    </figure>
                  </h3>
                </div>
                <p className="is-size-7-mobile has-text-centered has-text-weight-bold is-line-height-2-5">
                  Sophian's Contest 2024のテーマは
                  <br/>
                  「Frontier」です。
                  <br/>
                  2022年度はOrigin（起源）<br/>
                  2023年度はProgress（成長）<br/>
                  というテーマで本コンテストを行ってきましたが、<br/>
                  今年度はその先の最先端を追求し、<br/>
                  さらに進化したコンテストを開催したいと考えています。
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* CONTENTS */}
        <section id="contents" className="section">
          <div className="container" style={{maxWidth: 720}}>
            <div className="is-margin-bottom-3">
              <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Contents</h2>
            </div>
            <p
                className="notification has-background-white is-size-7-mobile is-margin-bottom-4"
                style={{margin: '0 auto'}}>
              Sophian's
              Contest（ソフィアンズコンテスト）は、自身の「他者のために、他者とともに」行う活動を発信し、上智大学や自身の個性や魅力を発信する場として「自己PR部門」と「SNS部門」の二部門で構成されています。その中で、最も多角的に活躍した人がグランプリ、準グランプリに輝きます。
            </p>
            <figure className="image is-margin-bottom-4 is-margin-top-2" style={{maxWidth: 480, margin: '0 auto'}}>
              <img src="/img/chart.jpg"/>
            </figure>
            <div className="columns is-centered is-multiline">
              <div className="column is-12">
                <StepBox
                    className="box"
                    style={{maxWidth: '100%'}}
                    src="/img/step1.png"
                    alt={"SDG's部門"}
                    boxTitle={
                      <>
                        <span className="tag has-text-futura-pt has-text-italic is-size-4">1</span>
                        <br/>
                        <br/>
                        <strong className="has-text-marker">「自己PR部門」</strong>
                      </>
                    }
                >
                  <p className="is-size-7-mobile">
                    自身のこれまでの功績、特技など、自分にしかない個性を発信する部門です。本選当日の発表で受賞者を決定します。
                  </p>
                </StepBox>
              </div>
              <div className="column is-12">
                <StepBox
                    className="box"
                    style={{maxWidth: '100%'}}
                    src="/img/step3.png"
                    alt={'SNS部門'}
                    boxTitle={
                      <>
                        <span className="tag has-text-futura-pt has-text-italic is-size-4">2</span>
                        <br/>
                        <br/>
                        <strong className="has-text-marker">「SNS部門」</strong>
                      </>
                    }
                >
                  <p className="is-size-7-mobile">
                    インフルエンス力を競う部門です。自己PR部門で発信する内容及び上智大学の魅力を、InstagramやTikTokなどのSNSを駆使し、発信します。いいね数や再生回数等を元に受賞者を決定します。
                  </p>
                </StepBox>
              </div>
            </div>
          </div>
        </section>
        {/* OUTLINE */}
        <section className="section has-background-white-bis">
          <div className="container" style={{maxWidth: 480}}>
            <div className="is-margin-bottom-3">
              <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Outline</h2>
            </div>
            <div className="columns is-mobile is-multiline">
              <div className="column is-5">
                <p className="title is-6">本選実施日時</p>
              </div>
              <div className="column is-7">
                <p className="subtitle is-6">
                  2024年11月3日
                  <br/>
                  <span className="has-text-marker is-margin-top-1" style={{display: "inline-block"}}>
                  <a href="https://t.livepocket.jp/e/3_zyy" target="_blank">チケット購入サイトはこちら</a>
                </span>
                </p>
              </div>
              <div className="column is-5">
                <p className="title is-6">WEB投票実施日時</p>
              </div>
              <div className="column is-7">
                <p className="subtitle is-6">
                  2024年10月1日 00:00:00 ~ 11月2日 23:59:59
                </p>
              </div>
              <div className="column is-5">
                <p className="title is-6">主催団体名</p>
              </div>
              <div className="column is-7">
                <p className="subtitle is-6">ソフィア祭実行委員会コンテスト局</p>
              </div>
              <div className="column is-5">
                <p className="title is-6">X</p>
              </div>
              <div className="column is-7">
                <p className="subtitle is-6 has-text-futura-pt has-text-italic">
                <span className="has-text-marker">
                  <a href="https://twitter.com/sophianscontest">@sophianscontest</a>
                </span>
                </p>
              </div>
              <div className="column is-5">
                <p className="title is-6">Instagram</p>
              </div>
              <div className="column is-7">
                <p className="subtitle is-6 has-text-futura-pt has-text-italic">
                <span className="has-text-marker">
                  <a href="https://www.instagram.com/sophianscontest">@sophianscontest</a>
                </span>
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* NEWS */}
        <section id="news" className="section">
          {news.pending || news.data.length === 0 ? null : (
              <div className="container" style={{maxWidth: 480}}>
                <div className="is-margin-bottom-3">
                  <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">News</h2>
                </div>
                <div id="news-columns" className="columns is-multiline is-mobile">
                  {news.data.map(item => (
                      <div key={item.id} className="column is-12">
                        <Link href={`/news/${item.id}`}>
                          <div className="box">
                            <article className="media">
                              <div className="media-content">
                                <div className="content">
                                  <p>
                                    <strong>{item.title}</strong>
                                    <br/>
                                    <span className="has-text-futura-pt has-text-italic has-text-grey-light is-size-7">
                                {item.publishedAt.toDateString()}
                              </span>
                                  </p>
                                </div>
                              </div>
                              {item.image && (
                                  <div className="media-right">
                                    <figure
                                        className="image"
                                        style={{
                                          width: 110,
                                          height: 60,
                                          backgroundSize: 'cover',
                                          backgroundPosition: 'center center',
                                          backgroundColor: '#fafafa',
                                          backgroundImage: `url(${item.image.url})`,
                                        }}
                                    >
                                      <img className="is-hidden" width="160" alt="Image"/>
                                    </figure>
                                  </div>
                              )}
                            </article>
                          </div>
                        </Link>
                      </div>
                  ))}
                </div>
              </div>
          )}
        </section>
        <Footer/>
      </main>
  );
}
